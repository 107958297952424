import { type HTMLAttributes, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { SmilesSvgRenderer } from 'react-ocl/minimal.js'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import FallbackImage from '../../common/components/FallbackImage'
import useResizeObserver from 'use-resize-observer'
import { Button } from 'react-bootstrap'
import useIsMobile from '../../common/hooks/useIsMobile'
import useDateLocale from '../../common/hooks/useDateLocale'
import { format } from 'date-fns'
import useProductDetails from '../hooks/useProductDetails'
import { type OrderLineItemResource } from '@amici/myamici-api-client'
import OrderLineItemWarningTooltip from './OrderLineItemWarningTooltip'
import styles from '../assets/scss/OrderLineItem.module.scss'

const FULL_LINE_ITEM_MIN_WIDTH_PX = 608
const FULL_INFO_VIEW_MIN_WIDTH_PX = 640

interface ReceiveOrderLineItemProps extends HTMLAttributes<HTMLDivElement> {
  item: OrderLineItemResource
  showReceiveItemModal: (item: OrderLineItemResource) => void
}

function ReceiveOrderLineItem ({
  item,
  showReceiveItemModal
}: Readonly<ReceiveOrderLineItemProps>): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { width: lineItemWidth = 0, ref: lineItemRef } = useResizeObserver()
  const { width: infoWidth = 0, ref: infoRef } = useResizeObserver()

  const showImage = lineItemWidth > FULL_LINE_ITEM_MIN_WIDTH_PX && !isMobile
  const fullInfoView = infoWidth > FULL_INFO_VIEW_MIN_WIDTH_PX

  const { data: product } = useProductDetails(item.product.id)

  const packSize = item.product.pack_size ?? 0
  const packSizeValue =
    ((item.product.no_of_units ?? 0) > 1
      ? `${item.product.no_of_units} x `
      : '') + packSize.toString()
  const packSizeUnit: string = item.product.pack_size_unit ?? ''

  const outstandingQty = item.quantity - (item.quantity_received ?? 0)
  const fullyReceived = item.quantity === item.quantity_received

  const dateLocale = useDateLocale()

  const deliveryDate = (): string | null => {
    if (item.quantity_received === item.quantity) {
      return item.date_received
        ? format(new Date(item.date_received), 'do MMM yyyy', {
            locale: dateLocale
          })
        : null
    }

    if (item.estimated_delivery_date) {
      return item.estimated_delivery_date
        ? format(new Date(item.estimated_delivery_date), 'do MMM yyyy', {
            locale: dateLocale
          })
        : null
    }

    return '-'
  }

  return (
    <div className={styles['receive-order-line-item']} ref={lineItemRef}>
      <div className={styles.title}>
        <div className={styles.name}>
          <Link to={`/purchasing/products/${item.product.id}`}>
            {item.product.description}
          </Link>

          <OrderLineItemWarningTooltip className={styles.issues} item={item} />
        </div>
      </div>
      <div
        className={classNames(styles.content, {
          [styles['image-hidden']]: !showImage,
          [styles.mobile]: isMobile
        })}
      >
        <div
          className={classNames(styles.details, { [styles.mobile]: isMobile })}
        >
          {showImage && (
            <div className={styles.img}>
              {item.image_url && (
                <FallbackImage
                  className={styles.img}
                  src={item.image_url ?? ''}
                  loading="lazy"
                  thumbnail
                />
              )}
              {!item.image_url && item.product.smiles && (
                <SmilesSvgRenderer
                  width={88}
                  height={88}
                  smiles={item.product?.smiles ?? ''}
                  ErrorComponent={FallbackImage}
                />
              )}
            </div>
          )}

          <div
            ref={infoRef}
            className={classNames(styles.info, {
              [styles.compact]: !fullInfoView
            })}
          >
            <table>
              <tbody>
                <tr>
                  <th>{t('order.item.card.part_number')}</th>
                  <td>{item.product.part_number}</td>
                </tr>
                <tr>
                  <th>{t('order.item.card.pack_size')}</th>
                  <td>
                    {t('order.item.card.pack_size_value', {
                      packSizeValue,
                      packSizeUnit: t([`units.${packSizeUnit}`, packSizeUnit], {
                        count: +packSize
                      })
                    })}
                  </td>
                </tr>
                {product?.cas_number && (
                  <tr>
                    <th>{t('receive_orders.item.card.cas_number')}</th>
                    <td>{product?.cas_number}</td>
                  </tr>
                )}
              </tbody>
            </table>

            <table className={styles.quantities}>
              <tbody>
                <tr>
                  <th>{t('receive_orders.item.card.ordered')}</th>
                  <td>{item.quantity}</td>
                </tr>
                <tr>
                  <th>{t('receive_orders.item.card.received')}</th>
                  <td>{item.quantity_received}</td>
                </tr>
                <tr>
                  <th>{t('receive_orders.item.card.outstanding')}</th>
                  <td>{outstandingQty}</td>
                </tr>
              </tbody>
            </table>

            <div className={styles.date}>
              <table>
                <tbody>
                  <tr>
                    <th>
                      {fullyReceived
                        ? t('receive_orders.item.card.delivered')
                        : t('receive_orders.item.card.due')}
                    </th>
                    <td>{deliveryDate()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          className={classNames(styles['receive-button'], {
            [styles.mobile]: isMobile
          })}
        >
          <Button
            type="submit"
            variant="outline-primary"
            className={classNames('rounded', { 'w-100': isMobile })}
            onClick={() => {
              showReceiveItemModal(item)
            }}
          >
            {fullyReceived
              ? t('receive_orders.item.card.edit_received')
              : t('receive_orders.item.card.receive_and_edit')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ReceiveOrderLineItem
