export const OrderRequestHistoryEventType = {
  CREATE_ORDER_REQUEST: 'create_order_request',
  EDIT_ORDER_REQUEST: 'edit_order_request',
  CANCEL_ORDER_REQUEST: 'cancel_order_request',
  ADD_ORDER_REQUEST_LINE_ITEM: 'add_order_request_line_item',
  EDIT_ORDER_REQUEST_LINE_ITEM: 'edit_order_request_line_item',
  DELETE_ORDER_REQUEST_LINE_ITEM: 'delete_order_request_line_item',
  EDIT_ORDER_REQUEST_DELIVERY_DETAILS: 'edit_order_request_delivery_details',
  APPROVE_ORDER_REQUEST: 'approve_order_request',
  SUBMIT_ORDER_REQUEST: 'submit_order_request',
  REJECT_ORDER_REQUEST: 'reject_order_request',
  CREATE_PURCHASE_ORDERS: 'create_purchase_orders',
  WITHDRAW_ORDER_REQUEST: 'withdraw_order_request',
  EDIT_ORDER_REQUEST_LINE_ITEM_SPEND_CATEGORY:
    'edit_order_request_line_item_spend_category',
  EDIT_ORDER_REQUEST_CUSTOM_ORDER_REF: 'edit_order_request_custom_order_ref',
  EDIT_ORDER_REQUEST_ORDER_METHOD: 'edit_order_request_order_method'
} as const
