import { type ReactElement } from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import type { OrderLineItemResource } from '@amici/myamici-api-client'
import { BsPencil } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import MaCheckbox, {
  type CheckedState
} from '../../common/components/MaCheckbox'
import MaActionIcon from '../../common/components/MaActionIcon'
import OrderLineItemWarningTooltip from './OrderLineItemWarningTooltip'
import styles from '../assets/scss/PurchaseOrderOrderItemsTable.module.scss'

export interface PurchaseOrderOrderItemsTableProps {
  lineItems: OrderLineItemResource[]
  selectedLineItemIds?: string[]
  onItemSelectedChange?: (
    lineItem: OrderLineItemResource,
    checked: CheckedState
  ) => void
  editable?: boolean
  onItemEdit?: (lineItem: OrderLineItemResource) => void
}

function PurchaseOrderOrderItemsTable ({
  lineItems,
  selectedLineItemIds,
  onItemSelectedChange,
  editable,
  onItemEdit
}: Readonly<PurchaseOrderOrderItemsTableProps>): ReactElement {
  const { t } = useTranslation()

  const unselectedLineItems = lineItems.filter(
    lineItem => !selectedLineItemIds?.includes(lineItem.id)
  )
  const allSelected = unselectedLineItems.length === 0
  const someSelected =
    !allSelected && unselectedLineItems.length < lineItems.length
  return (
    <Table responsive className={styles.table}>
      <thead>
        <tr>
          {editable && (
            <th className={styles.select}>
              <MaCheckbox
                checked={someSelected ? 'indeterminate' : allSelected}
                onCheckedChange={checked => {
                  let selectionChangeLineItems
                  if (checked) {
                    selectionChangeLineItems = unselectedLineItems
                  } else {
                    selectionChangeLineItems = lineItems
                  }
                  selectionChangeLineItems.forEach(lineItem => {
                    onItemSelectedChange?.(lineItem, checked)
                  })
                }}
              />
            </th>
          )}
          <th>{t('purchase_order.order_items.table.header.part_number')}</th>
          <th>{t('purchase_order.order_items.table.header.product')}</th>
          <th>{t('purchase_order.order_items.table.header.pack_size')}</th>
          <th className={styles.ordered}>
            {t('purchase_order.order_items.table.header.ordered')}
          </th>
          <th className={styles.received}>
            {t('purchase_order.order_items.table.header.received')}
          </th>
          <th>{t('purchase_order.order_items.table.header.due')}</th>
          <th className={styles.price}>
            {t('purchase_order.order_items.table.header.price')}
          </th>
          <th className={styles.subtotal}>
            {t('purchase_order.order_items.table.header.subtotal')}
          </th>
          <th>
            {t('purchase_order.order_items.table.header.spend_categories')}
          </th>
          {editable && (
            <th className={styles.actions}>
              {t('purchase_order.order_items.table.header.actions')}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {lineItems?.map(lineItem => (
          <tr key={lineItem.id}>
            {editable && (
              <td className={styles.select}>
                <MaCheckbox
                  checked={selectedLineItemIds?.includes(lineItem.id)}
                  onCheckedChange={checked => {
                    onItemSelectedChange?.(lineItem, checked)
                  }}
                />
              </td>
            )}
            <td>
              <OrderLineItemWarningTooltip
                className={styles.issues}
                item={lineItem}
              />
              {lineItem.product.part_number}
            </td>
            <td className={styles.product}>
              <Link to={`/purchasing/products/${lineItem.product.id}`}>
                {lineItem.product.description}
              </Link>
            </td>
            <td>
              {t('common.product.pack_size_value', {
                count: lineItem.product.no_of_units,
                packSizeValue: lineItem.product.pack_size,
                packSizeUnit: lineItem.product.pack_size_unit
              })}
            </td>
            <td className={styles.ordered}>{lineItem.quantity}</td>
            <td className={styles.received}>
              {lineItem.quantity_received ?? 0}
            </td>
            <td className={styles.due}>
              {lineItem.estimated_delivery_date
                ? t('date', {
                    date: new Date(lineItem.estimated_delivery_date)
                  })
                : null}
            </td>
            <td className={styles.price}>
              {t('common.price', {
                price: lineItem.price,
                currency: lineItem.currency ?? 'GBP'
              })}
            </td>
            <td className={styles.subtotal}>
              {t('common.price', {
                price: lineItem.quantity * (lineItem.price ?? 0),
                currency: lineItem.currency ?? 'GBP'
              })}
            </td>
            <td>
              {lineItem.spend_categories
                ? t('common.list', {
                    values: lineItem.spend_categories?.map(value => value.code)
                  })
                : ''}
            </td>
            {editable && (
              <td className={styles.actions}>
                <MaActionIcon
                  title={t('purchase_order.order_items.table.edit')}
                  onClick={() => {
                    onItemEdit?.(lineItem)
                  }}
                >
                  <BsPencil size={16} />
                </MaActionIcon>
              </td>
            )}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={editable ? 11 : 9}></td>
        </tr>
      </tfoot>
    </Table>
  )
}

export default PurchaseOrderOrderItemsTable
